import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "recibioAfiliado", 
    "recibidoPor", 
    "entregaRealizada", 
    "entregadoPor", 
    "fechaEntrega", 
    "observaciones"
  ]

  connect() {
    this.toggleEntregaRealizada()
    this.toggleRecibidoPor()
  }

  toggleEntregaRealizada() {
    const selectedValue = this.entregaRealizadaTargets.find(input => input.checked)?.value

    if (selectedValue === 'No') {
      // Deshabilitar todos los campos cuando 'entrega_realizada' es 'No'
      this.recibioAfiliadoTargets.forEach(input => input.disabled = true) // Deshabilita todos los inputs de recibioAfiliado
      this.toggleFieldDisabled(this.recibidoPorTarget, true) // Cambia el color y deshabilita 'recibidoPor'

      // Limpiar los valores de los campos cuando se selecciona 'No'
      this.clearFields()

      // Cambiar color de los labels en el fieldset a gris
      const labels = document.querySelectorAll('fieldset.entrega_recibio_afiliado');
      labels.forEach(label => {
        label.style.color = "gray"; // Cambia el color a gris
      });

    } else {
      // Habilitar los campos cuando 'entrega_realizada' es 'Sí'
      this.recibioAfiliadoTargets.forEach(input => input.disabled = false) // Habilita todos los inputs de recibioAfiliado

      // Restaurar el color de los labels en el fieldset
      const labels = document.querySelectorAll('fieldset.entrega_recibio_afiliado');
      labels.forEach(label => {
        label.style.color = ""; // Restaura el color original
      });

      // Restaurar el color del input de 'fecha_entrega'
      const fechaEntregaInput = document.getElementById('entrega_fecha_entrega');
      if (fechaEntregaInput) {
        fechaEntregaInput.style.setProperty('color', '', 'important'); // Restaura el color de texto
      }
    }
  }

  toggleRecibidoPor() {
    const selectedValue = this.recibioAfiliadoTargets.find(input => input.checked)?.value

    if (selectedValue === 'No') {
      // Habilita el campo 'recibidoPor' cuando 'recibioAfiliado' es 'No'
      this.toggleFieldDisabled(this.recibidoPorTarget, false)
    } else {
      // Deshabilita 'recibidoPor' y limpia su valor cuando 'recibioAfiliado' es 'Sí'
      this.toggleFieldDisabled(this.recibidoPorTarget, true)
      this.recibidoPorTarget.value = "" // Limpia el campo 'recibidoPor'
    }
  }

  toggleFieldDisabled(element, disabled) {
    element.disabled = disabled
    if (disabled) {
      element.classList.add("disabled-field")
      const label = element.previousElementSibling
      if (label) {
        label.style.color = "gray"; // Aplica color gris al label
      }
      element.style.backgroundColor = "#f0f0f0"; // Aplica fondo gris al input
      element.style.setProperty('border', '1px solid gray', 'important'); // Aplica borde gris al input
      element.style.color = "gray";
    } else {
      const label = element.previousElementSibling
      if (label) {
        label.style.color = ""; // Elimina el color gris
      }
      element.style.backgroundColor = ""; // Elimina el fondo gris
      element.style.setProperty('border', '', 'important'); // Elimina el borde gris
      element.style.color = "";
    }
  }

  // Función para limpiar los valores de los campos
  clearFields() {
    this.recibioAfiliadoTargets.forEach(input => input.checked = false) // Limpia las casillas de 'recibioAfiliado'
    this.recibidoPorTarget.value = "" // Limpia 'recibidoPor'
    this.entregadoPorTarget.value = "" // Limpia 'entregadoPor'
    this.fechaEntregaTarget.value = "" // Limpia 'fechaEntrega'
    this.observacionesTarget.value = "" // Limpia 'observaciones'
  }
}
