import { Controller } from "@hotwired/stimulus"
const options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  };

export default class extends Controller {
  async connect() {
    console.log("Probando geolocaiton");

    try {
      const crd = await this.getAccurateLocation();

      if (crd && crd.latitude && crd.longitude) {
        console.log("Your current position is:");
        console.log(`Latitude paciente: ${crd.latitude}`);
        console.log(`Longitude paciente: ${crd.longitude}`);
        console.log(`More or less ${crd.accuracy} meters.`);

        // Asignar los valores a los campos ocultos del formulario
        const latInput = document.getElementById('lat');
        const lngInput = document.getElementById('lng');
        const btnGenerar = document.getElementById('btn-generar');

        if (latInput && lngInput) {
          latInput.value = crd.latitude;
          lngInput.value = crd.longitude;
        }

        if (btnGenerar) {
          btnGenerar.disabled = false;
        }
      } else {
        console.warn('No se pudo obtener la ubicación precisa.');
      }
    } catch (err) {
      console.warn(`ERROR: ${err.message}`);
    }
  }

  async getAccurateLocation() {
    const apiKey = 'AIzaSyBPBi2MufCgUAzkiWKDoCMKqhwYbZQDPUc'; // Reemplaza con tu API key de Google

    try {
      const response = await fetch(`https://www.googleapis.com/geolocation/v1/geolocate?key=${apiKey}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error(`Error fetching location: ${response.statusText}`);
      }

      const data = await response.json();

      // Asegurarse de que la respuesta contiene los datos esperados
      if (data && data.location) {
        console.log({
          latitude: data.location.lat,
          longitude: data.location.lng,
          accuracy: data.accuracy,
        })
        return {
          latitude: data.location.lat,
          longitude: data.location.lng,
          accuracy: data.accuracy,
        };
      } else {
        throw new Error('No se encontraron datos de ubicación.');
      }
    } catch (error) {
      console.error('Error fetching accurate location:', error);
      throw error;
    }
  }




}
