import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    //console.log("Sidebar");
    this.sidebarElement = this.element;
    this.sidebarContent = this.sidebarElement.querySelector(".sidebar-content");
    this.closeButtons = this.sidebarElement.querySelectorAll(".close-sidebar");

    // Agregar un manejador de clic al documento
    document.addEventListener("click", function (event) {
      const turboFrame = document.querySelector("#sidebar");
      const elementToCheck = document.querySelector(".sidebar-content"); // Cambia esto al selector de tu elemento específico

      if (elementToCheck && !elementToCheck.contains(event.target)) {
        // turboFrame.innerHTML = "";
        // console.log("Se hizo clic fuera del elemento.");
        if (turboFrame) {
          // Eliminar el Turbo Frame
          turboFrame.innerHTML = "";
        }
      }
    });
  }

  activarSegundoSidebar(e) {
    e.stopPropagation();
    e.preventDefault();
    // console.log("Botón:", e.currentTarget);

    const boton = e.currentTarget;
    const content = boton
      .closest(".filter_section")
      .querySelector(".sidebar-content");
    // console.log("Contenido:", content);

    if (content.classList.contains("oculto")) {
      content.classList.remove("oculto");
    } else {
      content.classList.add("oculto");
    }
  }

  closeTurboFrame(e) {
    e.stopPropagation();
    e.preventDefault();
    const turboFrame = document.querySelector("#sidebar");

    if (turboFrame) {
      // Eliminar el Turbo Frame
      turboFrame.innerHTML = "";
    }
  }

  updateParams(e) {
    let paramName, paramValue, button, buttonText;
    const radioButton = e.target;
    paramName = radioButton.name;
    paramValue = radioButton.value;


    if (paramName === 'fecha_asignada_desde' || paramName === 'fecha_asignada_hasta') {
      button = document.querySelector(`button[data-param="fecha"]`);
      const startDateInput = this.element.querySelector('input[name="fecha_asignada_desde"][id="fecha_desde"]');
      const endDateInput = this.element.querySelector('input[name="fecha_asignada_hasta"][id="fecha_hasta"]');

      const startDate = startDateInput.value;
      const endDate = endDateInput.value;

      if (startDate && endDate) {
        buttonText = `${startDate.split('-').reverse().join('/')} - ${endDate.split('-').reverse().join('/')}`;
      } else if (startDate) {
        buttonText = `Desde: ${startDate.split('-').reverse().join('/') }`;
      } else if (endDate) {
        buttonText = `Hasta: ${endDate.split('-').reverse().join('/') }`;
      }
    } else if (paramName === 'financiadora') {
      button = document.querySelector(`button[data-param="${paramName}"]`);
      let inputElement  = this.element.querySelector(`input[name="${paramName}"][value="${paramValue}"]`);
      let labelElement = inputElement.closest('div').querySelector('label');
      buttonText = labelElement.textContent;
    } else {
      button = document.querySelector(`button[data-param="${paramName}"]`);
      // Find the selected radio button
      const selectedRadioButton = this.element.querySelector(`input[name="${paramName}"][value="${paramValue}"]`);
      const labelElement = selectedRadioButton.closest('div').querySelector('label');
      buttonText = labelElement.textContent;
    }

    // Remove all child nodes from the button
    while (button.firstChild) {
      button.removeChild(button.firstChild);
    }

    // Create a new text node and insert it into the button
    const newText = document.createTextNode(buttonText);
    button.appendChild(newText);

    // Create a new icon and insert it into the button
    const newIcon = document.createElement('i');
    newIcon.className = 'fa-solid fa-chevron-right';
    button.appendChild(newIcon);
  }
}
